import { Grid, Paper } from '@mantine/core';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import StatsControls from './metrics/StatsControls';
import { Notes, ShoppingCart, Truck, TruckDelivery } from 'tabler-icons-react';
import StatsVerticalBarChart from './metrics/StatsVerticalBarChart';
import { StatisticContext } from '../context/ContextWrapper';

function Home() {
  const { t } = useTranslation();
  const ctxStatistic = useContext(StatisticContext);

  const dataStatsControls = [
    {
      icon: Notes,
      label: t('StatsControlsBarchart.Requirements'),
      value: ctxStatistic.entities[0]?.total_inquiry?.count || 0,
    },
    {
      icon: Truck,
      label: t('StatsControlsBarchart.AvailableVehicle'),
      value: ctxStatistic.entities[0]?.total_available_vehicle?.count || 0,
    },
    {
      icon: ShoppingCart,
      label: t('StatsControlsBarchart.OrderRequirements'),
      value: ctxStatistic.entities[0]?.total_ordered_inquiry?.count || 0,
    },
    {
      icon: TruckDelivery,
      label: t('StatsControlsBarchart.SendOrders'),
      value: ctxStatistic.entities[0]?.total_delivered_inquiry?.count || 0,
    },
  ];

  const dataResourceIndicator = {
    labels: ctxStatistic.entities[0]?.resources_indicator.name_list,
    datasets: [
      {
        label: t('StatsControlsBarchart.OpenResources'),
        data: ctxStatistic.entities[0]?.resources_indicator.open_count_list,
        backgroundColor: 'rgba(153, 102, 255, 0.2)',
        borderColor: 'rgb(153, 102, 255)',
        borderWidth: 1,
      },
      {
        label: t('StatsControlsBarchart.DeliveredResources'),
        data: ctxStatistic.entities[0]?.resources_indicator
          .delivered_count_list,
        backgroundColor: 'rgba(255, 159, 64, 0.2)',
        borderColor: 'rgb(255, 159, 64)',
        borderWidth: 1,
      },
    ],
  };

  const dataResource = {
    labels: ctxStatistic.entities[0]?.resources.name_list,
    datasets: [
      {
        label: t('StatsControlsBarchart.OpenResources'),
        data: ctxStatistic.entities[0]?.resources.open_count_list,
        backgroundColor: 'rgba(153, 102, 255, 0.2)',
        borderColor: 'rgb(153, 102, 255)',
        borderWidth: 1,
      },
      {
        label: t('StatsControlsBarchart.DeliveredResources'),
        data: ctxStatistic.entities[0]?.resources.delivered_count_list,
        backgroundColor: 'rgba(255, 159, 64, 0.2)',
        borderColor: 'rgb(255, 159, 64)',
        borderWidth: 1,
      },
    ],
  };

  const dataSection = {
    labels: ctxStatistic.entities[0]?.section.name_list,
    datasets: [
      {
        label: t('StatsControlsBarchart.Employee'),
        data: ctxStatistic.entities[0]?.section.employee_count_list,
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
      },
    ],
  };

  return (
    <>
      <Paper my="md" p="xl">
        <Grid>
          <Grid.Col xs={12}>
            <StatsControls data={dataStatsControls} />
          </Grid.Col>
          <Grid.Col xs={12}>
            <StatsVerticalBarChart
              title={
                t('StatsControlsBarchart.Resources') +
                ' (' +
                t('StatsControlsBarchart.Total') +
                ': ' +
                (ctxStatistic.entities[0]?.resource_inquiry[0]?.sum_total ||
                  0) +
                ')'
              }
              data={dataResourceIndicator}
              stacked={false}
            />
          </Grid.Col>
          <Grid.Col xs={12}>
            <StatsVerticalBarChart
              title={
                t('StatsControlsBarchart.Employee') +
                ': ' +
                ctxStatistic.entities[0]?.section.employee_count
              }
              data={dataSection}
              stacked={false}
            />
          </Grid.Col>
          <Grid.Col xs={12}>
            <StatsVerticalBarChart
              title={t('StatsControlsBarchart.RequestedResources')}
              data={dataResource}
              stacked={true}
            />
          </Grid.Col>
        </Grid>
      </Paper>
    </>
  );
}

export default Home;
